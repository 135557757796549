import { useQuery } from "@tanstack/react-query";
import Swal from "sweetalert2";
import PageDescription from "../../layout/page-description";
import TableNeogen from "../../layout/table-neogen";
import interactionTypesService from "../../services/interaction-types.service";
import interactionsService from "../../services/interactions.service";
import Loader2 from "../utilities/Loader2";
import SwalNeogenFire from "../../layout/swal-neogen";

export default function MessageTypes() {
    const messageTypesQuery = useQuery(["messageTypes"], async () => {
        const response = await interactionTypesService.getAll();
        if (response) {
            return response.data;
        }
    });

    if (messageTypesQuery.isLoading) {
        return <Loader2 />;
    }

    return (
        <>
            <PageDescription
                title="Message Types"
                description="This is a list of all of the message types in the system."
                buttons={[
                    {
                        label: "New Message Type",
                        onClick: () => {
                            // setIsEdit(false);
                            // setShow(true);
                        },
                        icon: "fal fa-plus",
                        type: "primary",
                        className: "inline-block w-96",
                    },
                ]}
            />
            <TableNeogen
                formatters={[
                    {
                        field: "customerViewable",
                        type: "Boolean",
                    },
                ]}
                entries={messageTypesQuery.data ?? []}
                actions={[
                    {
                        label: "Delete",
                        onClick: (id) => {
                            SwalNeogenFire({
                                title: "Are you sure?",
                                text: "You won't be able to revert this!",
                                icon: "warning",
                                showCancelButton: true,
                                confirmButtonText: "Yes, delete it!",

                                confirmButtonColor: "#d33",
                            }).then((result) => {
                                if (result.isConfirmed) {
                                    interactionsService
                                        .deleteByID(id)
                                        .then(() => {
                                            SwalNeogenFire({
                                                icon: "success",
                                                title: "Deleted!",
                                                text: "Your message has been deleted.",
                                                showConfirmButton: false,
                                                timer: 1500,
                                            });
                                            messageTypesQuery.refetch();
                                        })
                                        .catch(() => {
                                            Swal.fire("Error!", "Something went wrong.", "error");
                                        });
                                }
                            });
                        },
                        icon: "fal fa-trash",
                        className: "bg-red-500 hover:bg-red-600 text-white",
                    },
                ]}
            />
        </>
    );
}
