import { useState } from "react";
import { useQueryClient } from "@tanstack/react-query";
import { useNavigate } from "react-router";
import Swal from "sweetalert2";
import businessProductService from "../../../services/business-product.service";
import businessRuleService from "../../../services/business-rule.service";
import { BusinessProduct } from "../../../typings/api/business-product";
import { BusinessRuleGroup } from "../../../typings/api/business-rule-group";
import InputControlled from "../../../layout/input-controlled";
import ModalDialog from "../../../layout/modal-dialog";
import SwalNeogenFire from "../../../layout/swal-neogen";

export default function AddEditBusinessProduct(props: AddEditBusinessProductProps) {
    const [name, setName] = useState("");
    const cache = useQueryClient();
    const [description, setDescription] = useState("");
    const [error, setError] = useState("");
    const navigate = useNavigate();
    async function save() {
        try {
            const response = await businessProductService.create({ name, description });
            if (response?.data?.id) {
                props.close();
                cache.invalidateQueries(["business-products"]);
                setName("");
                setDescription("");
                SwalNeogenFire({
                    title: "Success",
                    text: "Product created successfully",
                    icon: "success",
                    showConfirmButton: false,
                    timer: 1500,
                });

                // })
            } else {
                SwalNeogenFire({
                    title: "Error",
                    text: "Error creating product",
                    icon: "error",
                    showConfirmButton: false,
                    timer: 1500,
                });
            }
        } catch (error: any) {
            setError(error.message);
        }
    }

    return (
        <>
            <ModalDialog
                show={props.show}
                title={(props.group ? "Edit" : "Add") + " Business Product"}
                okAction={save}
                close={props.close}
            >
                <InputControlled label="Name" value={name} setValue={setName} />
                <InputControlled label="Description" value={description} setValue={setDescription} />
            </ModalDialog>
        </>
    );
}

type AddEditBusinessProductProps = {
    show: boolean;
    close: () => void;
    group?: BusinessProduct;
};
