import { useState } from "react";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useNavigate } from "react-router";
import Swal from "sweetalert2";
import businessProductService from "../../services/business-product.service";
import processflowGroupService from "../../services/processflow-group.service";
import processflowStageService from "../../services/processflow-stage.service";
import processflowService from "../../services/processflow.service";
import BoxChoices from "../../layout/box-choices";
import PageDescription from "../../layout/page-description";
import TableNeogen from "../../layout/table-neogen";
import Loader2 from "../utilities/Loader2";
import AddEditBusinessProduct from "./modals/add-edit-business-product";
import SwalNeogenFire from "../../layout/swal-neogen";

export default function ListProducts() {
    const [showAddBusinessRule, setShowAddBusinessRule] = useState(false);
    const navigate = useNavigate();
    const cache = useQueryClient();

    const productQuery = useQuery(["business-products"], async () => {
        const response = await businessProductService.getAll();
        if (response) {
            return response.data;
        }
    });

    return (
        <>
            <PageDescription
                title="Business Rule Products"
                description="Please select a product you would like to see the business rules or create a new one"
                buttons={[
                    {
                        label: "Add Product",
                        icon: "fal fa-plus",
                        onClick: () => {
                            setShowAddBusinessRule(true);
                        },
                    },
                ]}
            />
            {productQuery.isLoading ? (
                <Loader2 />
            ) : (
                <BoxChoices
                    urlBase="/rules/"
                    icon="fa-box-check"
                    data={productQuery.data ?? []}
                    deleteFunction={(groupId: number | string) => {
                        SwalNeogenFire({
                            title: "Are you sure?",
                            text: "You won't be able to revert this!",
                            icon: "warning",
                            showCancelButton: true,
                            confirmButtonColor: "#3085d6",
                            confirmButtonText: "Yes, delete it!",
                        }).then(async (result) => {
                            if (result.value) {
                                try {
                                    await businessProductService.deleteByID(Number(groupId));
                                } catch (e) {
                                    console.log(e);
                                }
                                cache.invalidateQueries(["business-products"]);
                                SwalNeogenFire({
                                    title: "Deleted!",
                                    text: "Your business rule product has been deleted.",
                                    icon: "success",
                                    showConfirmButton: false,
                                    timer: 1500,
                                });
                            }
                        });
                    }}
                />
            )}
            <AddEditBusinessProduct show={showAddBusinessRule} close={() => setShowAddBusinessRule(false)} />
        </>
    );
}
