import { useState } from "react";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import Swal from "sweetalert2";
import menuService from "../../../services/menu.service";
import roleService from "../../../services/role.service";
import { Navigation } from "../../../typings/api/navigations";
import Roles from "../../admin/roles/roles";
import CheckBoxNeoGenControlled from "../../../layout/checkbox-controlled";
import InputControlled from "../../../layout/input-controlled";
import ModalDialog from "../../../layout/modal-dialog";
import PrintPre from "../../../layout/print-pre";
import SelectNeoGen from "../../../layout/select-neogen";
import Loader2 from "../../utilities/Loader2";
import SwalNeogenFire from "../../../layout/swal-neogen";

export default function AddEditMenu(props: AddEditMenuProps) {
    const [name, setName] = useState(props.editingEntry?.name);
    const [icon, setIcon] = useState(props.editingEntry?.icon);
    const [href, setHref] = useState(props.editingEntry?.href);
    const cache = useQueryClient();
    const [requiredRoles, setRequiredRoles] = useState<string[]>(props.editingEntry?.requiredRolesObject ?? []);
    const allRolesQuery = useQuery(["allRoles"], async () => {
        const response = await roleService.getAll();
        if (response) {
            return response.data;
        }
    });
    /*
    {
  "id": 5,
  "name": "Process Flows (Admin)",
  "icon": "fa-list-check",
  "href": "/processflow-categories",
  "current": 0,
  "requiredRoles": "{\"roleGroups\": [\"URL_ADMIN\"]}",
  "requiredRolesObject": [
    "URL_ADMIN"
  ]
} 
    */
    if (!allRolesQuery.isSuccess) {
        return <Loader2 />;
    }

    async function save() {
        const fields: Navigation = {
            name: name ?? "",
            icon: icon ?? "",
            href: href ?? "",
            current: 0,
            requiredRoles: JSON.stringify({ roleGroups: requiredRoles }),
        };
        let response;
        if (props.isEdit) {
            response = await menuService.update(props.editingEntry?.id, fields);
        } else {
            response = await menuService.create(fields);
        }

        if (response) {
            SwalNeogenFire({
                title: "Success",
                text: props.isEdit ? "Changes Saved" : "Menu added successfully",
                icon: "success",
                showConfirmButton: false,
                timer: 1500,
            }).then(() => {
                cache.invalidateQueries(["menus"]);
                props.close();
            });
        }
    }
    return (
        <>
            <ModalDialog
                close={props.close}
                show={props.show}
                title={props.isEdit ? "Edit Menu" : "Add Menu"}
                size="md"
                okAction={save}
                okText={props.isEdit ? "Save" : "Add"}
            >
                {/* <PrintPre>
                    {props.editingEntry}
                </PrintPre> */}
                <InputControlled label="The text that will appear in the menu" value={name ?? ""} setValue={setName} />
                <InputControlled label="Icon (i.e. fas fa-user-plus)" value={icon ?? ""} setValue={setIcon} />
                <InputControlled label="URL that the menu item points to" value={href ?? ""} setValue={setHref} />
                <label htmlFor="email" className={"block text-sm font-medium dark:text-gray-400 text-gray-700"}>
                    Required Roles to access and see this en
                </label>
                <div className="grid grid-cols-3">
                    {allRolesQuery.data?.map((role) => {
                        return (
                            <div key={role.id}>
                                <CheckBoxNeoGenControlled
                                    label={role.name}
                                    value={requiredRoles.includes(role.roleCode ?? "")}
                                    setValue={(value) => {
                                        console.log(value?.currentTarget.checked);
                                        if (value?.currentTarget.checked) {
                                            setRequiredRoles([...requiredRoles, role.roleCode ?? ""]);
                                        } else {
                                            setRequiredRoles(requiredRoles.filter((i) => i !== role.roleCode));
                                        }
                                    }}
                                    name={role.roleCode ?? ""}
                                />
                            </div>
                        );
                    })}
                </div>
            </ModalDialog>
        </>
    );
}

type AddEditMenuProps = {
    show: boolean;
    close: () => void;
    isEdit: boolean;
    editingEntry?: Navigation;
};
