import { z } from "zod";
import { makeRequest, parseSchema } from "../../shared/utils";
import { userSchema } from "../domain/user";
import { roleGroupSchema } from "../../../role-groups/domain/role-group";

export const userResponseSchema = z.object({
    id: z.string(),
    createdAt: z.coerce.date().nullish(),
    updatedAt: z.coerce.date().nullish(),
    invitedAt: z.coerce.date().nullish(),
    accountSetupAt: z.coerce.date().nullish(),
    company: z.number().nullish(),
    name: z.string().nullish(),
    email: z.string(),
    phone: z.string().optional().nullable(),
    firstName: z.string().nullish(),
    lastName: z.string().nullish(),
    utm: z.string().optional().nullable(),
    affiliateUserId: z.string().optional().nullable(),
    defaultProcessflowId: z.number().optional().nullable(),
    emailVerified: z.coerce.boolean().nullish(),
    userStatus: z.enum(["invited", "active"]).nullish(),
    accountManagerUserId: z.string().nullish(),
    cpaUserId: z.string().nullish(),
    taxAttorneyUserId: z.string().nullish(),
    roleGroups: z.array(roleGroupSchema).nullish(),
    companyRoleGroups: z.array(roleGroupSchema).nullish(),
    lastLoggedIn: z.coerce.date().nullish(),
});

const getUsersResponseSchema = z.array(userResponseSchema);

export const getUsers = async ({
    authToken,
    filters,
    role,
}: {
    authToken: string;
    filters?: any;
    role?: "Accountant" | "Affiliate" | "Doc collector" | "Tax attorney" | "Closer";
}) => {
    const response = await makeRequest({
        method: "get",
        path: "/users",
        authToken,
        queryParams: { filter: JSON.stringify(filters), role },
    });

    const userDtos = parseSchema(getUsersResponseSchema, response.data);

    const users = parseSchema(
        z.array(userSchema),
        userDtos.map((dto) => ({
            ...dto,
            company: dto.company || undefined,
            name: dto.name || undefined,
        })),
    );

    return users;
};

export const getMyUsers = async ({ authToken }: { authToken: string }) => {
    const response = await makeRequest({
        method: "get",
        path: "/my-users",
        authToken,
    });

    const userDtos = parseSchema(getUsersResponseSchema, response.data);

    const users = parseSchema(
        z.array(userSchema),
        userDtos.map((dto) => ({
            ...dto,
            company: dto.company || undefined,
            name: dto.name || undefined,
        })),
    );

    return users;
};
