import { z } from "zod";
import ModalDialog from "../../layout/modal-dialog";
import { useForm } from "../../hooks/useForm";
import { Form } from "../../layout/form/form";
import { TextField } from "../../layout/form/text-field";
import { useMutation } from "@tanstack/react-query";
import { updateUser } from "../../jason-proof-of-concept/users/actions/update-user";
import { getAuthTokenNoThrow } from "../../services/auth-header";
import ButtonNeoGen from "../../layout/button-neogen";
import { replaceCompanyRoles } from "../../jason-proof-of-concept/users/actions/replace-company-roles";
import { User } from "../../jason-proof-of-concept/users/domain/user";
import { SelectField } from "../../layout/form/select-field";
import { RoleGroup } from "../../role-groups/domain/role-group";
import { createMagicLink } from "../../magic-links/actions/create-magic-link";
import { MagicLinkTypes } from "../../magic-links/domain/magic-link";
import { useAuth } from "../../auth/use-auth";

export const ReInviteUserModal = ({
    onClose,
    user,
    onUserInvited: onUserInvited,
    companyId,
}: {
    onClose: () => any;
    user: User;
    onUserInvited: () => any;
    companyId: number;
}) => {
    const authToken = getAuthTokenNoThrow() || "no-auth-token";
    const auth = useAuth();
    const authedUser = auth.expectUser();
    if (!user.id) {
        throw new Error("User must have an id");
    }

    const mutation = useMutation({
        mutationFn: async () => {
            await updateUser({ authToken, id: user.id, data: { invitedAt: new Date(), userStatus: "invited" } });
            await createMagicLink({
                authToken,
                data: {
                    type: MagicLinkTypes.userInvite,
                    createdById: authedUser.id,
                    data: {
                        firstName: user.firstName,
                        lastName: user.lastName,
                        email: user.email,
                        phone: user.phone,
                    },
                },
            });
        },
    });

    const handleSubmit = async () => {
        await mutation.mutateAsync();
        onUserInvited();
    };

    return (
        <ModalDialog show title={"Resend invitation email"} close={onClose} showOk={false} showCancel={false}>
            <div>
                <p className="py-4">
                    Resend an invitation email to {[user.firstName, user.lastName].join(" ").trim()}:{" "}
                    <b>{user.email}</b>
                </p>
                <div style={{ display: "flex", gap: 10, marginTop: 20 }}>
                    <ButtonNeoGen onClick={onClose} type="outline">
                        Cancel
                    </ButtonNeoGen>
                    <ButtonNeoGen block type="primary" disabled={mutation.isLoading} onClick={handleSubmit}>
                        Resend invitation email
                    </ButtonNeoGen>
                </div>
            </div>
        </ModalDialog>
    );
};
