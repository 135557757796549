import { LockClosedIcon } from "@heroicons/react/24/solid";
import { Link } from "react-router-dom";
import logo from "../../images/logo.png";
import authService from "../../services/auth.service";
import { useRecoilState } from "recoil";
import userAtom from "../../atoms/userAtom";
import { ClearERCUser } from "../../typings/api/clear-erc-user";
import logService from "../../services/log-service";
import { Log } from "../../typings/api";
import { getGeoData } from "../../jason-proof-of-concept/other/actions/getMyIp";
import { Form } from "../../layout/form/form";
import { z } from "zod";
import { useForm } from "../../hooks/useForm";
import { EmailField } from "../../layout/form/email-field";
import { PasswordField } from "../../layout/form/password-field";
import { useMutation } from "@tanstack/react-query";

const schema = z.object({
    email: z.string().email(),
    password: z.string(),
});

type Data = z.infer<typeof schema>;

export default function SignInForm() {
    const [user, setUser] = useRecoilState<ClearERCUser>(userAtom);

    const form = useForm({ schema });

    const mutation = useMutation({
        mutationFn: async ({ email, password }: Data) => {
            try {
                const response = await authService.login(email, password);

                if (response.id) {
                    setUser(response.user);
                    // This request is sometimes blocked by ad-blockers (AdBlock, uBlock etc)
                    getGeoData().then((geoData) => {
                        const newLogin: Log = {
                            userId: response.id,
                            remoteIp: geoData?.IPv4,
                            eventTimestamp: new Date().toISOString(),
                            url: "/login",
                            body: JSON.stringify(geoData),
                            executionTime: 0,
                        };
                        logService.postURL("/logs", newLogin);
                    });
                    authService.roles = response.roles;
                    const redirectUri = decodeURIComponent(
                        new URLSearchParams(window.location.search).get("redirectUri") || "",
                    );
                    const appBaseUrl = window.location.origin;

                    if (redirectUri && redirectUri.match(/^\/[\w-]+$/)) {
                        window.location.href = appBaseUrl + redirectUri;
                    } else {
                        window.location.href = appBaseUrl + "/dashboard"; // redirect to dashboard by default
                    }
                } else {
                    throw new Error(response.message);
                }
            } catch (error: any) {
                throw new Error(
                    // Temporary hack to display user-friendly error messages on login.
                    error.message === "Request failed with status code 422"
                        ? "Invalid username or password."
                        : error.message === "Invalid Username/Password"
                        ? "Incorrect username and password combination."
                        : error.message,
                );
            }
        },
    });

    const handleSubmit = (data: Data) => {
        mutation.mutate({ email: data.email, password: data.password });
    };

    const isLoading = mutation.isLoading;

    return (
        <div className="min-h-full flex items-center justify-center py-12 px-0 sm:px-6 lg:px-12 ">
            <div className="max-w-lg md:w-[600px] p-6 md:p-14 rounded-xl bg-indigo-800 shadow-2xl shadow-slate-700/90 border-white/80 dark:border-slate-500 border    ">
                <div className="pb-6">
                    <img className="mx-auto h-12 w-auto" src={logo} alt="ClearERC logo" />
                    <h1 className="text-center text-white mt-4">Sign in</h1>
                </div>
                <Form onSubmit={form.handleSubmit(handleSubmit)} error={mutation.error as any}>
                    <div className="flex flex-col gap-3">
                        <div>
                            <EmailField label="Email" {...form.getFieldProps("email")} />
                            <PasswordField label="Password" {...form.getFieldProps("password")} />
                        </div>
                        <div className="flex items-center justify-between">
                            <div className="text-sm">
                                <Link
                                    to="/forgot-password"
                                    className="font-medium text-indigo-200 hover:text-indigo-500"
                                >
                                    Forgot your password?
                                </Link>
                            </div>
                            <div className="text-sm">
                                <Link to="/sign-up" className="font-medium text-indigo-200 hover:text-indigo-500">
                                    Create a new Account
                                </Link>
                            </div>
                        </div>
                        <button
                            type="submit"
                            disabled={isLoading}
                            className={
                                "group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md " +
                                "text-white bg-indigo-600 hover:bg-indigo-500 hover:text-gray-200 focus:outline-none focus:ring-2 " +
                                "focus:ring-offset-2 focus:ring-indigo-500 transition-all duration-500"
                            }
                        >
                            <span className="absolute left-0 inset-y-0 flex items-center pl-3">
                                {isLoading ? (
                                    <svg
                                        className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                    >
                                        <circle
                                            className="opacity-25"
                                            cx="12"
                                            cy="12"
                                            r="10"
                                            stroke="currentColor"
                                            strokeWidth="4"
                                        ></circle>
                                        <path
                                            className="opacity-75"
                                            fill="currentColor"
                                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                        ></path>
                                    </svg>
                                ) : (
                                    <LockClosedIcon
                                        className="h-5 w-5  c group-hover:text-indigo-400 transition-all duration-500"
                                        aria-hidden="true"
                                    />
                                )}
                            </span>
                            Sign in
                        </button>
                    </div>
                </Form>
            </div>
        </div>
    );
}
