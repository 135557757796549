import { useQuery } from "@tanstack/react-query";
import Filter from "../../../layout/filter";
import PageDescription from "../../../layout/page-description";
import usersService from "../../../services/users.service";
import Loader3 from "../../utilities/Loader3";
import companyService from "../../../services/company.service";
import { useEffect, useState } from "react";
import { Search, Option, Detail } from "searchpal";
import ButtonNeoGen from "../../../layout/button-neogen";
import PrintPre from "../../../layout/print-pre";
import userCompanyService from "../../../services/user-company.service";
import { ClearERCUser } from "../../../typings/api/clear-erc-user";
import Avatar from "react-avatar";
import processflowProgressService from "../../../services/processflow-progress.service";
import { useParams } from "react-router-dom";
import { JsonViewer } from "@textea/json-viewer";
import CardNeoGen from "../../../layout/card-neogen";
import SwalNeogenFire from "../../../layout/swal-neogen";
import { Md5 } from "../../utilities/md5";
import UserCompanyPicker from "../../../layout/user-company-picker";
import { Company } from "../../../typings/api";
import { useNavigate } from "react-router-dom";

export default function FixProgress({ isUser, isCompany }: { isUser?: boolean; isCompany?: boolean }) {
    const params = useParams();
    const [open, setOpen] = useState(isUser || isCompany ? false : true);
    const navigate = useNavigate();
    // const [companiesAndUsers, setCompaniesAndUsers] = useState<any[]>([]);
    // Get all users
    const progressFlowData = useQuery(["progressflow-data"], async () => {
        const response = await processflowProgressService.getProgress(params.userId ?? "", 7);
        if (response) {
            console.error(response.data);
            // Return sorted data (by name)
            return response.data;
        }
        return [];
    });
    const allUsersQuery = useQuery(["users-with-companies"], async () => {
        const response = await usersService.getAll();
        if (response) {
            console.error(response.data);
            // Return sorted data (by name)
            return response.data;
        }
        return [];
    });
    const allCompaniesQuery = useQuery(["companies"], async () => {
        const response = await companyService.getAll();
        if (response) {
            // Return sorted data (by name)
            return response.data;
        }
        return [];
    });
    // useEffect(() => {
    //     console.log(allUsersQuery.data);
    //     console.log(allUserCompaniesQuery.data);
    //     if (allUsersQuery.data && allUserCompaniesQuery.data) {
    //         const usersAndCompanies = allUsersQuery.data.map((user: ClearERCUser) => {
    //             user.fullCompany = allUserCompaniesQuery.data.filter((company) => {
    //                 return user.companyId === company.id;
    //             });
    //         });
    //         console.log(usersAndCompanies);
    //         setCompaniesAndUsers(usersAndCompanies);
    //     }
    // }, [allUsersQuery.data, allUserCompaniesQuery.data]);

    return (
        <>
            <PageDescription
                title={"Fix " + (isUser ? "User " : "") + (isCompany ? "Company " : "") + "Progress"}
                description="Allows you to compare multiple processflows and fix missing progress."
            />
            {allUsersQuery.isLoading || allCompaniesQuery.isLoading || progressFlowData.isLoading ? (
                <Loader3 />
            ) : (
                <div>
                    {/* Select Processflow */}
                    {/* <main className={"-mt-0 " + "p-0 my-2 pb-3" + " "}> */}
                    {/* <div className="w-full  px-0 xs:px-6 lg:max-w-full lg:px-0"> */}
                    {/* Main 3 column grid */}
                    {/* <div className="grid grid-cols-1 gap-4 items-start lg:grid-cols-1 "> */}
                    {/* Left column */}
                    {/* <div className="grid grid-cols-1 gap-4 lg:col-span-2"> */}
                    {/* Welcome panel */}
                    {/* <section aria-labelledby="profile-overview-title"> */}
                    {/* <div className="rounded-xl bg-white   shadow dark:bg-gray-800"> */}
                    {/* <div className="bg-gradient-to-br to-white from-slate-100 dark:from-slate-900 dark:to-gray-800 border-2 border-white rounded-xl p-6 dark:bg-gray-800 dark:border-gray-700 dark:shadow-inner shadow-sm"> */}
                    {/* <div> */}
                    {/* <div className="sm:flex sm:items-center sm:justify-between "> */}
                    {/* <div className="sm:flex-grow sm:space-x-5"> */}
                    {/* <Filter
                                                                label="Search"
                                                                className="w-64"
                                                                description="Select the user you want to check."
                                                                onChange={(idx) => {
                                                                    console.log(idx);
                                                                }}
                                                                options={allUsersQuery.data?.map((user) => {
                                                                    return {
                                                                        name:
                                                                            (user.name?.length ?? 0) > 0
                                                                                ? user.name + " (" + user.email + ")"
                                                                                : user.email,
                                                                        id: user.id,
                                                                    };
                                                                })}
                                                            /> */}
                    <></>
                    {isUser || isCompany ? (
                        <>
                            <ButtonNeoGen onClick={() => setOpen(true)}>Search for a something to repair</ButtonNeoGen>
                            <div>
                                {/* <PrintPre>{progressFlowData.data}</PrintPre> */}
                                <span className="font-bold mr-5">Number of Processflows:</span>
                                <span>{progressFlowData.data?.length}</span>
                                <div className="grid grid-cols-3 gap-5">
                                    {progressFlowData.data?.map((progress) => {
                                        return (
                                            <div className="block" key={progress.id}>
                                                <div className="col-span-1 max-h-72  overflow-scroll-y">
                                                    <CardNeoGen>
                                                        <div className="">
                                                            <span className="font-bold mr-5">ID: </span>
                                                            {progress.id}
                                                        </div>
                                                        <div className="font-bold mr-5">
                                                            <span className="font-bold mr-5">Responses:</span>
                                                            {
                                                                Object.keys(
                                                                    JSON.parse(progress.data ?? "{}").entriesByField,
                                                                )?.length
                                                            }
                                                        </div>
                                                        <ButtonNeoGen
                                                            type="danger"
                                                            className="my-5"
                                                            // label="Delete"
                                                            onClick={() => {
                                                                SwalNeogenFire({
                                                                    title: "Are you sure?",
                                                                    html:
                                                                        "You will not be able to recover this processflow progress with <span className='text-red-500'><b >" +
                                                                        Object.keys(
                                                                            JSON.parse(progress.data ?? "{}")
                                                                                .entriesByField,
                                                                        )?.length +
                                                                        " responses!</span></b>",
                                                                    icon: "warning",
                                                                    showCancelButton: true,
                                                                    confirmButtonText: "Yes, delete it!",
                                                                    cancelButtonText: "No, keep it",
                                                                }).then((result) => {
                                                                    if (result.isConfirmed) {
                                                                        processflowProgressService
                                                                            .deleteByID(progress.id)
                                                                            .then((res) => {
                                                                                // console.log(res);
                                                                                // if (res) {
                                                                                SwalNeogenFire({
                                                                                    title: "Deleted!",
                                                                                    text: "Your processflow progress has been deleted.",
                                                                                    icon: "success",
                                                                                    showConfirmButton: false,
                                                                                    timer: 1500,
                                                                                }).then(() => {
                                                                                    window.location.reload();
                                                                                });
                                                                                // }
                                                                            });
                                                                    } else {
                                                                        SwalNeogenFire({
                                                                            title: "Cancelled",
                                                                            text: "Your processflow progress is safe :)",
                                                                            icon: "error",
                                                                            showConfirmButton: false,
                                                                            timer: 1500,
                                                                        });
                                                                    }
                                                                });
                                                            }}
                                                        >
                                                            <i className="fas fa-trash-alt mr-2"></i> Delete Processflow
                                                            Progress
                                                        </ButtonNeoGen>

                                                        <div className="font-bold mr-5">
                                                            <span className="font-bold mr-5">Details:</span>
                                                            {Object.entries(
                                                                JSON.parse(progress.data ?? "{}").entriesByField,
                                                            ).map((f) => {
                                                                return (
                                                                    <div key={f[0]}>
                                                                        <span className="font-bold mr-5">Field:</span>
                                                                        {f[0]}
                                                                        <br />
                                                                        <span className="font-bold mr-5">Value:</span>
                                                                        <PrintPre>{f[1] ?? ""}</PrintPre>
                                                                        {/* <PrintPre> */}
                                                                        {/* {f} */}
                                                                        {/* </PrintPre> */}
                                                                        <br />
                                                                        {/* <div className="ml-5">
                                                                                                        <span className="font-bold mr-5">
                                                                                                            Responses:
                                                                                                        </span>
                                                                                                        {
                                                                                                            <PrintPre>
                                                                                                                {f}
                                                                                                            </PrintPre>
                                                                                                        }
                                                                                                        {
                                                                                                            <PrintPre>
                                                                                                                {
                                                                                                                    JSON.parse(
                                                                                                                        progress.data ??
                                                                                                                            "{ }",
                                                                                                                    )
                                                                                                                        .entriesByField
                                                                                                                }
                                                                                                            </PrintPre>
                                                                                                        }
                                                                                                    </div> */}
                                                                    </div>
                                                                );

                                                                // (field: any) => {
                                                                //     return (
                                                                //         <div
                                                                //             key={field.id}
                                                                //             className="ml-5"
                                                                //         >
                                                                //             <span className="font-bold mr-5">
                                                                //                 Field:
                                                                //             </span>
                                                                //             {field.name}
                                                                //             <div className="ml-5">
                                                                //                 <span className="font-bold mr-5">
                                                                //                     Responses:
                                                                //                 </span>
                                                                //                 {/* {field.entries.map(
                                                                //                 (
                                                                //                     entry: any,
                                                                //                 ) => {
                                                                //                     return (
                                                                //                         <div
                                                                //                             key={
                                                                //                                 entry.id
                                                                //                             }
                                                                //                             className="ml-5"
                                                                //                         >
                                                                //                             <span className="font-bold mr-5">
                                                                //                                 Entry:
                                                                //                             </span>
                                                                //                             {
                                                                //                                 entry.id
                                                                //                             }
                                                                //                         </div>
                                                                //                     );
                                                                //                 },
                                                                //             )} */}
                                                                //             </div>
                                                                //         </div>
                                                                //     );
                                                            })}
                                                        </div>

                                                        {
                                                            // <PrintPre>
                                                            //     {JSON.parse(
                                                            //         progress.data ??
                                                            //             "{}",
                                                            //     )}
                                                            // </PrintPre>
                                                        }
                                                        {
                                                            // <JsonViewer
                                                            //     value={JSON.parse(
                                                            //         progress.data ??
                                                            //             "{}",
                                                            //     )}
                                                            // />
                                                        }
                                                    </CardNeoGen>
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        </>
                    ) : (
                        <></>
                    )}
                    {/* /*
                    <div><a href= {
    "mailto:"+company.ownedBy?.email
}

><span className="font-bold mr-3">Email:</span><span className="" title= {
    company.ownedBy?.email
}

> {
    company.ownedBy?.email?.length ?? 0>20 ? company.ownedBy?.email.substring(0, 17)+"..."
    : company.ownedBy?.email
}

</span></a></div><div><a href= {
    "tel:"+company.ownedBy?.phone
}

><span className="font-bold mr-3">Phone:</span><span className=""> {
    company.ownedBy?.phone
}

</span></a></div> */}
                    {/* <PrintPre>{companiesAndUsers}</PrintPre> */}
                    <UserCompanyPicker
                        open={open}
                        setOpen={setOpen}
                        setCompany={(company: Company) => {
                            navigate(`/admin/tools/fix-progress/company/${company?.id}`);
                        }}
                        setUser={(user: ClearERCUser) => {
                            navigate(`/admin/tools/fix-progress/user/${user?.id}`);
                        }}
                        userList={allUsersQuery.data ?? []}
                        companyList={allCompaniesQuery.data ?? []}
                    />
                    {/* <Search
                        label="Search for a something to repair..."
                        open={open}
                        onClose={() => setOpen(false)}
                        link={({ href, children }) => <a href={href}>{children}</a>}
                    >
                        {allUsersQuery.data?.map((user: ClearERCUser) => (
                            <Option
                                label={user?.name ?? "No name"}
                                sublabel={user?.email}
                                media={
                                    <img
                                        alt="User Image"
                                        src={
                                            "https://www.gravatar.com/avatar/" +
                                            Md5.hashAsciiStr(user.email ?? "") +
                                            "?d=wavatar"
                                        }
                                    />
                                    // <span>
                                    //         {user.email}
                                    // </span>
                                    // <Avatar
                                    //     size="24px"
                                    //     name={user.firstName as string}
                                    //     round={true}
                                    //     email={user.email}
                                    //     color={"#61baf3"}
                                    // />
                                }
                                preview={
                                    <img
                                        alt="User Image"
                                        src={
                                            "https://www.gravatar.com/avatar/" +
                                            Md5.hashAsciiStr(user?.email ?? "") +
                                            "?d=wavatar&s=256"
                                        }
                                    />
                                }
                                href={`/admin/tools/fix-progress/user/${user?.id}`}
                                keywords={(getKeywords) => getKeywords(user?.email, user?.name)}
                                key={user?.id}
                            >
                                <Detail
                                    label="User Details"
                                    value={
                                        <>
                                            <div>
                                                <span className="font-bold mr-3">Name:</span>
                                                <span className="">
                                                    {user.firstName} {user.lastName}
                                                </span>
                                            </div>
                                            <div>
                                                <span className="font-bold mr-3">Email:</span>
                                                <span className="">{user.email}</span>
                                            </div>
                                            <div>
                                                <span className="font-bold mr-3">Phone:</span>
                                                <span className="">{user.phone}</span>
                                            </div>
                                            <div>
                                                <span className="font-bold mr-3">Role Groups:</span>
                                                <span className="">
                                                    {user.roleGroups?.map((rg) => {
                                                        return (
                                                            <span key={rg.id}>
                                                                {rg.name}
                                                                <br />
                                                            </span>
                                                        );
                                                    })}
                                                </span>
                                            </div>
                                            <div>
                                                <span className="font-bold mr-3">Last Logged In:</span>
                                                <span className="">
                                                    {new Date(user.lastLoggedIn ?? "")?.toLocaleString()}
                                                </span>
                                            </div>
                                        </>
                                    }
                                />
                                
                            </Option>
                        ))}
                        {allCompaniesQuery.data?.map((company) => (
                            <Option
                                label={company?.name ?? "No name"}
                                sublabel={company?.ownedBy?.email ?? "No email"}
                                // img={{
                                //     src: user.avatar,
                                //     alt: `${user.name} profile picture`,
                                // }}
                                media={
                                    <img
                                        alt="Company Image"
                                        src={
                                            "https://www.gravatar.com/avatar/" +
                                            Md5.hashAsciiStr(company?.ownedBy?.email ?? "") +
                                            "?d=identicon"
                                        }
                                    />
                                }
                                preview={
                                    <img
                                        alt="Company Image"
                                        src={
                                            "https://www.gravatar.com/avatar/" +
                                            Md5.hashAsciiStr(company?.ownedBy?.email ?? "") +
                                            "?d=identicon&s=256"
                                        }
                                    />
                                }
                                href={`/admin/tools/fix-progress/company/${company?.id}/$company?.ownedBy?.id}`}
                                keywords={(getKeywords) => getKeywords(company?.email, company?.name)}
                                key={company?.id}
                            >
                                <Detail
                                    label="Owner Details: "
                                    value={
                                        <div>
                                            {company.ownedBy?.firstName} {company.ownedBy?.lastName}
                                            <div>
                                                <span className="font-bold mr-3">Name:</span>
                                                <span className="">
                                                    {company.ownedBy?.firstName} {company.ownedBy?.lastName}
                                                </span>
                                            </div>
                                            <div>
                                                <span className="font-bold mr-3">Email:</span>
                                                <span className="">{company.ownedBy?.email}</span>
                                            </div>
                                            <div>
                                                <span className="font-bold mr-3">Phone:</span>
                                                <span className="">{company.ownedBy?.phone}</span>
                                            </div>
                                            <div>
                                                <span className="font-bold mr-3">Role Groups:</span>
                                                <span className="">
                                                    {company.ownedBy?.roleGroups?.map((rg) => {
                                                        return (
                                                            <span key={rg.id}>
                                                                {rg.name}
                                                                <br />
                                                            </span>
                                                        );
                                                    })}
                                                </span>
                                            </div>
                                            <div>
                                                <span className="font-bold mr-3">Last Logged In:</span>
                                                <span className="">
                                                    {new Date(company.ownedBy?.lastLoggedIn ?? "")?.toLocaleString()}
                                                </span>
                                            </div>
                                        </div>
                                    }
                                />
                            </Option>
                        ))}
                    </Search> */}
                </div>
                // </div>
                // </div>
                // </div>
                // </div>
                // </section>
                // </div>
                // </div>
                // </div>
                // </main>
                // </div>
            )}
        </>
    );
}
