// import { PencilIcon, LinkIcon, CheckIcon, BriefcaseIcon, CalendarIcon, CurrencyDollarIcon, MapPinIcon } from "@heroicons/react/24/solid";
import { DndContext, closestCenter, KeyboardSensor, PointerSensor, useSensor, useSensors } from "@dnd-kit/core";
import {
    arrayMove,
    SortableContext,
    sortableKeyboardCoordinates,
    verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import _ from "lodash";
import { v4 as uuidv4 } from "uuid";
import { useEffect, useRef, useState } from "react";
// import { Tab } from "react-bootstrap";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useParams } from "react-router";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import businessRuleGroupService from "../../services/business-rule-group.service";
import processflowGroupService from "../../services/processflow-group.service";
import processflowStageService from "../../services/processflow-stage.service";
import processflowService from "../../services/processflow.service";
import { BusinessRuleGroup } from "../../typings/api/business-rule-group";
import { ProcessFlow } from "../../typings/api/processflow";
import { ProcessFlowStage } from "../../typings/api/processflow-stage";
import { Entry } from "../../layout/add-to-list";
import BreadCrumbs from "../../layout/breadcrumbs";
import ButtonNeoGen from "../../layout/button-neogen";
import CommandPaletteEnabler from "../../layout/command-palette-enabler";
// import ModalSingleText from "../layout/modal-single-text";
import PageDescription from "../../layout/page-description";
// import PrintPre from "../layout/print-pre";
import TableNeogen from "../../layout/table-neogen";
import Loader2 from "../utilities/Loader2";
import { StepEntry } from "./components/step-entry";
import AddEdit from "./modals/add-edit";
import AddEditGroup from "./modals/add-edit-group";
import { AddEditStage } from "./modals/add-stage";
import { SortableItem } from "./modals/sortable-item";
import DuplicateGroup from "./modals/duplicate-group";
import RulePicker from "../business-rules/modals/rule-picker";
import PrintPre from "../../layout/print-pre";
import useNavigateProcess from "./hooks/use-navigate-process";
import { ProcessflowStage } from "../../jason-proof-of-concept/processflow-stages/domain/processflow-stage";
import SwalNeogenFire from "../../layout/swal-neogen";

const selectionOptions = [
    { id: 1, title: "Current Step", description: "Show options from the current step" },
    { id: 2, title: "All Steps", description: "Show options from the entire product" },
];
const andOrOptions = [
    { id: 1, title: "All Must Match", description: "Show options from the current step" },
    { id: 2, title: "At Least One", description: "Show options from the entire product" },
];

const q1 = uuidv4();
const q2 = uuidv4();
const q3 = uuidv4();
const q4 = uuidv4();

/**
 * Renders the Process Flow Admin page.
 * @returns The Process Flow Admin page.
 */
export default function ProcessFlowAdmin() {
    const [show, setShow] = useState(false);
    const [showEditRules, setShowEditRules] = useState(false);
    const [processflowId, setProcessflowId] = useState<number>(-1);
    const fieldsSet = useRef(false);
    const [stepIsEdit, setStepIsEdit] = useState(false);
    const [showAddEditProcessFlowGroup, setShowAddEditProcessFlowGroup] = useState(false);
    const [showAddStage, setShowAddStage] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [showDuplicate, setShowDuplicate] = useState(false);
    const [entries, setEntries] = useState<Entry[]>([]);
    const [isStep, setIsStep] = useState(false);
    const [isStage, setIsStage] = useState(false);
    const { processflowGroup } = useParams();
    const cache = useQueryClient();
    const navigate = useNavigate();
    const [rgAreOr, setRgAreOr] = useState<boolean>(false);
    const pGroup: number = Number(processflowGroup) ?? 7;
    const {
        setStageId: setStage,
        nextStep,
        previousStep,
        nextStage,
        previousStage,
        setGroupId,
    } = useNavigateProcess(pGroup);
    const [enabledRules, setEnabledRules] = useState<BusinessRuleGroup[]>([]);

    const sensors = useSensors(
        useSensor(PointerSensor),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates,
        }),
    );

    /**
     * Handles the drag end event by moving one item to another position and then saving to the API and updating the atom and cache
     *
     * @param {any} event - the drag end event.
     * @returns None
     */
    function handleDragEnd(event: any) {
        const { active, over } = event;

        if (active.id !== over.id) {
            // Has this moved?  Checks if the active filter is different from the over filter.
            setEntries((items) => {
                // const oldIndex = items.indexOf(active.field);
                const oldIndex = items.findIndex((i) => i.id == active.id);
                const newIndex = items.findIndex((i) => i.id == over.id);

                // const newIndex = items.indexOf(over.field);
                const newPositions = arrayMove(items, oldIndex, newIndex);
                let order = 0;
                for (const item of newPositions) {
                    // item.order = order;
                    processflowService.patchURL(processflowService.endpoint + "/" + item.id, { order });
                    order++;
                }
                return newPositions;
            });

            // TODO: Find out which ones actually need to be removed
            cache.removeQueries();

            // TODO: Update the atom and

            // fieldsSet.current = false;
        }
    }

    /**
     * Get the processflow group with the given id, including the required roles.
     * @param {number} groupId - the id of the group to get
     * @returns {Promise<ProcessflowGroup>} - the group with the given id, including the required roles.
     */
    const groupQuery = useQuery([q1, pGroup], async () => {
        //   const groupQuery = useQuery(["processflowGroups", "getOneIncludingRequiredRoles", processflowGroup], async () => {
        const response = await processflowGroupService.getOneIncludingRequiredRoles(Number(processflowGroup));
        if (response) {
            return response.data[0];
        }
    });

    /**
     * Gets all the stages for the given group id.
     *
     * @param {number} groupId - the group id to get the stages for.
     * @returns {Promise<ProcessFlowStage[]>} - the stages for the given group id.
     */
    const stageQuery = useQuery([q2, pGroup], async () => {
        //   const stageQuery = useQuery(["processflow-stages", "getAllByGroupId", processflowGroup], async () => {
        const response = await processflowStageService.getAllByGroupIdIncludingRuleGroups(Number(processflowGroup));
        if (response) {
            return response.data?.map((stage: ProcessFlowStage) => {
                return {
                    id: stage.id,
                    name: stage.name,
                    description: stage.description,
                    order: stage.order,
                    status: stage.status,
                    current: stage.current,
                    group: stage.group,
                    isPublic: stage.isPublic,
                    businessRuleGroups: stage.businessRuleGroups ?? [],
                };
            });
        }
    });

    /**
     * A custom hook that returns the process flow entries for the given group id.
     * This is the code that is being rewritten to pull entrees separately from the stages.
     *
     * @param {string} processflowGroup - the group id to get the process flow entries for.
     * @returns {ProcessFlow[]} - the process flow entries for the given group id.
     */
    const processflowQuery = useQuery(
        [q3, pGroup],
        async () => {
            //   const entriesQuery = useQuery(["processflow-entries", processflowGroup], async () => {
            const response = await processflowService.getAllByGroupId(Number(processflowGroup));
            if (response) {
                // alert(JSON.stringify(response.data));
                const mapped = response.data
                    .map((entry: ProcessFlow) => {
                        const newEntry: any = {};
                        newEntry.id = entry.id;
                        newEntry.title = entry.title ?? "";
                        newEntry.businessRuleGroups = entry.businessRuleGroups ?? [];
                        newEntry.order = entry.order;
                        newEntry.stageName =
                            stageQuery.data?.find((stage: ProcessFlowStage) => stage.id === entry.stage)?.name ??
                            "Unknown";
                        return Object.assign(entry, newEntry);
                        // return newEntry;
                    })
                    .sort((a, b) => a.order - b.order);
                // alert(JSON.stringify(mapped));
                return mapped;
            } else {
                // console.error(response);
            }
        },
        { enabled: stageQuery.isSuccess },
    );

    // TODO: To be honest I'm not sure why this is an effect and not just a function call
    // If it should be, update the docs to explain why
    useEffect(() => {
        if (processflowQuery.data && processflowQuery.isSuccess && enabledRules !== null) {
            const entry = processflowQuery.data.find(
                (entry: ProcessFlow) => Number(entry.id) === Number(processflowId),
            );
            const dbRules = [entry?.businessRuleGroups];
            const same = _.isEqual(dbRules[0], enabledRules);
            if (!same) {
                const removals = _.differenceWith(dbRules[0], enabledRules, _.isEqual);
                const additions = _.differenceWith(enabledRules, dbRules[0], _.isEqual);

                // Remove processflow rule group entries
                for (const removal of removals) {
                    processflowService
                        .getURL(
                            "/processflow-rule-groups?filter=" +
                                JSON.stringify({
                                    where: { processflowId: processflowId, businessRuleGroupId: removal.id },
                                }),
                        )
                        .then((response) => {
                            if (response) {
                                for (const item of response.data) {
                                    const newId = item.id;
                                    processflowService.deleteURL("/processflow-rule-groups/" + newId);
                                }
                            }
                        });
                }

                // Add processflow rule group entries
                for (const addition of additions) {
                    processflowService.postURL("/processflow-rule-groups", {
                        processflowId: processflowId,
                        businessRuleGroupId: addition.id,
                    });
                }
                // cache.removeQueries();
                fieldsSet.current = false;
            }
        }
    }, [cache, enabledRules, processflowQuery.data, processflowQuery.isSuccess, processflowId]);

    // TODO: In reality there should only be one way top add a rule to a step or field. You can add it, or remove it, but if it's there, it wil lbe used in the calculator
    //   const allRulesAndGroupsQuery = useQuery(["business-rule-groups"], async () => {
    //     const response = await businessRuleGroupService.getAllIncludingChildren();
    //     if (response) {
    //       return response.data;
    //     }
    //   });

    useEffect(() => {
        if (processflowQuery.data && processflowId) {
            // const enabled =
            const entry = processflowQuery.data.find(
                (entry: ProcessFlow) => Number(entry.id) === Number(processflowId),
            );
            const rules = processflowQuery.data.map((rr) => {
                // alert(JSON.stringify(rr));
                return rr.businessRuleGroups;
            });
            // alert(JSON.stringify(rules));
            setEnabledRules(entry?.businessRuleGroups ?? null);
            // setEnabledRules(rules[0]);
        }
    }, [processflowQuery.data, processflowQuery.isLoading, processflowId]);

    useEffect(() => {
        if (processflowQuery.isSuccess && !fieldsSet.current) {
            fieldsSet.current = true;
            setEntries(processflowQuery.data ?? []);
        }
    }, [processflowQuery.data, processflowQuery.isSuccess]);

    function addItem() {
        // add item to list
        setShow(true);
    }
    function addStage() {
        setShowAddStage(true);
    }

    function removeStage(id: number) {
        return processflowStageService.deleteByID(id);
    }
    function removeStep(id: number) {
        return processflowService.deleteByID(id);
    }
    const mutation = useMutation(removeStage, {
        onSuccess: () => {
            cache.invalidateQueries(["processflow-stages", processflowGroup]);
            fieldsSet.current = false;
            SwalNeogenFire({
                title: "Success",
                text: "Stage removed successfully",
                icon: "success",
                showConfirmButton: false,
                timer: 1500,
            });

            // }
        },
    });
    // const stepMutation = useMutation(removeStep, {
    //     onSuccess: () => {
    //         cache.invalidateQueries(["processflow-entries", groupId]);

    //         SwalNeogenFire({
    //             title: "Success",
    //             text: "Step removed successfully",
    //             icon: "success",
    //             showConfirmButton: false,
    //             timer: 1500,
    //         });

    //         // }
    //     },
    // });
    function navigateTo(groupId: any) {
        // alert(groupId);
        setGroupId(groupId);
        navigate("/processflow-wizard/" + groupId);
    }

    return (
        <>
            <BreadCrumbs
                addMb={true}
                pages={[
                    {
                        name: "ProcessFlows",
                        href: "/processflow-categories",
                    },
                    {
                        name: (groupQuery.data?.name ?? "Unknown") + " ProcessFlow",
                        href: `/processflow-groups/${processflowGroup}`,
                        current: true,
                    },
                ]}
            />
            <PageDescription
                title={groupQuery.data?.name ?? "Unknown"}
                description={groupQuery.data?.description}
                description2={
                    "Accessible by Role Groups: " +
                    groupQuery.data?.roleGroups?.map((roleGroup) => roleGroup.name).join(", ")
                }
                buttons={[
                    {
                        label: "Run Process Flow",
                        onClick: () => navigateTo(processflowGroup),
                        icon: "fal fa-wand-magic-sparkles",
                    },
                    {
                        label: "Edit Process Flow",
                        onClick: () => {
                            setShowAddEditProcessFlowGroup(true);
                        },
                        bg: "bg-blue-600",
                        icon: "fal fa-pencil-alt",
                    },
                    {
                        label: "Duplicate Process Flow",
                        onClick: () => {
                            setShowDuplicate(true);
                        },
                        bg: "bg-green-600 dark:bg-green-800",
                        icon: "fal fa-clone",
                    },
                    {
                        label: "Delete Process Flow",
                        onClick: () => {
                            SwalNeogenFire({
                                title: "Are you sure?",
                                text: "You won't be able to revert this!",
                                icon: "warning",
                                confirmButtonText: "Yes, delete it!",
                                showCancelButton: true,
                                confirmButtonColor: "#3085d6",
                            }).then(async (result) => {
                                if (result.value) {
                                    try {
                                        await processflowGroupService.deleteByID(Number(processflowGroup));
                                    } catch (e) {
                                        // console.log(e);
                                    }
                                    try {
                                        await processflowService.deleteByGroupId(Number(processflowGroup));
                                    } catch (e) {
                                        // console.log(e);
                                    }
                                    try {
                                        await processflowStageService.deleteByGroupId(Number(processflowGroup));
                                    } catch (e) {
                                        // console.log(e);
                                    }
                                    cache.invalidateQueries(["processflow-stages", processflowGroup]);
                                    SwalNeogenFire({
                                        title: "Deleted!",
                                        text: "Your process flow has been deleted.",
                                        icon: "success",
                                        showConfirmButton: false,
                                        timer: 1500,
                                    }).then(() => {
                                        navigate("/processflow-categories");
                                    });
                                }
                            });
                        },
                        bg: "bg-red-600",
                        icon: "fal fa-trash-alt",
                    },
                ]}
            >
                {groupQuery.isLoading ? <Loader2 /> : <></>}
            </PageDescription>
            {processflowQuery.isLoading ? (
                <Loader2 />
            ) : (
                <>
                    <div className="bg-white p-5 rounded-xl shadow mb-5 dark:bg-gray-800 ">
                        <div className="">
                            <div className="sm:flex sm:items-center">
                                <div className="sm:flex-auto">
                                    <h1 className="text-xl  text-gray-900 dark:text-green-600">Steps</h1>
                                    <p className="mt-2 text-sm text-gray-700 dark:text-gray-400">
                                        These are the individual steps that are taken to complete the process flow
                                    </p>
                                </div>
                                <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
                                    <ButtonNeoGen
                                        className="mt-3"
                                        onClick={() => {
                                            addItem();
                                        }}
                                        text="Add Step"
                                        type="info"
                                        icon="fas fa-plus"
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="mt-5">
                            <DndContext
                                sensors={sensors}
                                collisionDetection={closestCenter}
                                onDragEnd={handleDragEnd}
                                onDragStart={console.log}
                            >
                                <SortableContext items={entries} strategy={verticalListSortingStrategy}>
                                    {entries.map((entry) => (
                                        <SortableItem
                                            leftSide={true}
                                            key={entry.id}
                                            id={entry.id}
                                            value={
                                                <StepEntry
                                                    entries={entries}
                                                    setShowEditRules={setShowEditRules}
                                                    setEntries={setEntries}
                                                    key={entry.id}
                                                    entry={entry}
                                                    setIsStep={setIsStep}
                                                    setStepIsEdit={setStepIsEdit}
                                                    setId={setProcessflowId}
                                                    setShow={setShow}
                                                />
                                            }
                                        />
                                    ))}
                                </SortableContext>
                            </DndContext>
                        </div>
                    </div>

                    <div className="bg-white p-5 rounded-xl shadow dark:bg-gray-600">
                        <div>
                            <div className="sm:flex sm:items-center">
                                <div className="sm:flex-auto">
                                    <h1 className="text-xl font-semibold text-gray-900 dark:text-gray-300">Stages</h1>
                                    <p className="mt-2 text-sm text-gray-700 dark:text-gray-300">
                                        Stages are the categories of steps that are grouped together.
                                    </p>
                                </div>
                                <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
                                    <ButtonNeoGen
                                        className="mt-3"
                                        type="info"
                                        onClick={() => {
                                            setIsEdit(false);
                                            addStage();
                                        }}
                                        text="Add Stage"
                                        icon="fas fa-plus"
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="mt-5">
                            <TableNeogen
                                entries={stageQuery.data ?? []}
                                formatters={[
                                    {
                                        field: "isPublic",
                                        type: "Boolean",
                                    },
                                ]}
                                actions={[
                                    {
                                        label: "Edit",
                                        onClick: (id: number) => {
                                            // alert("Edit " + id);
                                            setIsEdit(true);
                                            setProcessflowId(id);
                                            setShowAddStage(true);
                                        },
                                        className:
                                            "bg-indigo-500 hover:bg-indigo-700 text-white font-bold py-2 px-4 rounded",
                                        disabled: (entry: ProcessFlowStage) => {
                                            return false;
                                        },
                                        icon: "fas fa-edit",
                                    },
                                    {
                                        label: "Rules",
                                        onClick: (id: number) => {
                                            setIsStage(true);
                                            setIsStep(false);
                                            setProcessflowId(id);
                                            setShowEditRules(true);
                                        },
                                        icon: "fas fa-shield-alt",
                                        // size: "xs",
                                        type: "info",
                                        className: "z-10 mx-auto my-auto px-auto py-auto",
                                    },
                                    {
                                        label: "Delete",
                                        onClick: (id: number) => {
                                            SwalNeogenFire({
                                                title: "Are you sure you want to delete this stage?",
                                                text: "You won't be able to revert this!",
                                                icon: "warning",
                                                showCancelButton: true,
                                                confirmButtonColor: "#3085d6",
                                                cancelButtonColor: "#d33",
                                                confirmButtonText: "Yes, delete it!",
                                            }).then((result) => {
                                                if (result.isConfirmed) {
                                                    mutation.mutate(id);
                                                }
                                            });
                                        },
                                        className: "bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded",
                                        icon: "fas fa-trash-alt",
                                        disabled: (entry: ProcessFlowStage) => {
                                            return false;
                                        },
                                    },
                                ]}
                            />
                        </div>
                    </div>
                </>
            )}
            {show && <AddEdit show={show} close={() => setShow(false)} isEdit={stepIsEdit} id={processflowId} />}
            {showAddEditProcessFlowGroup && (
                <AddEditGroup
                    isEdit={true}
                    show={showAddEditProcessFlowGroup}
                    close={() => setShowAddEditProcessFlowGroup(false)}
                    group={groupQuery.data}
                />
            )}
            {/* <PrintPre>{processflowId}</PrintPre> */}
            {showEditRules && (
                <RulePicker
                    // rules={allRulesAndGroupsQuery.data ?? []}
                    // enabled={enabledRules ?? []}
                    // setEnabled={setEnabledRules}
                    setAreOr={setRgAreOr}
                    areOr={rgAreOr}
                    // existing={entries}
                    // andOrOptions={andOrOptions}
                    isStep={isStep}
                    isStage={isStage}
                    target={
                        isStep
                            ? processflowQuery.data?.find((entry: ProcessFlow) => {
                                  return Number(entry.id) === Number(processflowId);
                              })?.name
                            : stageQuery.data?.find((entry: any) => {
                                  return Number(entry.id) === Number(processflowId);
                              })?.name
                    }
                    targetEntry={
                        isStep
                            ? processflowQuery.data?.find((entry: ProcessFlow) => {
                                  return Number(entry.id) === Number(processflowId);
                              })
                            : stageQuery.data?.find((entry: any) => {
                                  return Number(entry.id) === Number(processflowId);
                              })
                    }
                    // selectionOptions={selectionOptions}
                    processflowId={processflowId}
                    show={showEditRules}
                    close={() => setShowEditRules(false)}
                />
            )}
            {/* {showEditRules && <StepRules show={showEditRules} close={() => setShowEditRules(false)} id={id} groupId={Number(groupId)} />} */}
            {showAddStage && (
                <AddEditStage
                    // save={(value:string) => {
                    //     saveStage(value);
                    // }}
                    id={processflowId}
                    isEdit={isEdit}
                    show={showAddStage}
                    close={() => setShowAddStage(false)}
                />
            )}
            {showDuplicate && (
                <DuplicateGroup
                    show={showDuplicate}
                    close={() => setShowDuplicate(false)}
                    title={groupQuery.data?.name}
                    description={groupQuery.data?.description}
                />
            )}
        </>
    );
}
