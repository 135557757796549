import { useState } from "react";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import Swal from "sweetalert2";
import roleGroupService from "../../../services/role-group.service";
import PageDescription from "../../../layout/page-description";
import TableNeogen from "../../../layout/table-neogen";
import Loader2 from "../../utilities/Loader2";
import AddEditRoleGroup from "./modals/add-edit-role-group";
import SwalNeogenFire from "../../../layout/swal-neogen";

export default function RoleGroups() {
    const queryCache = useQueryClient();
    const [showAddEditGroup, setShowAddEditGroup] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [roles, setRoles] = useState<number[]>([]);
    const [group, setGroup] = useState<any>();
    const roleGroupQuery = useQuery(["roleGroupEntries"], async () => {
        const response = await roleGroupService.getAllRoleGroups();
        if (response) {
            return response.data.map((roleGroup) => {
                return {
                    id: roleGroup.id,
                    name: roleGroup.name,
                    description: roleGroup.description,
                    owner: roleGroup.owner,
                    isPublic: roleGroup.isPublic,
                    roles: roleGroup.roles
                        ?.map((role) => {
                            return role.name;
                        })
                        .join(", "),
                    roleIds: roleGroup.roles?.map((role) => {
                        return role.id ?? -1;
                    }),
                };
            });
        }
    });
    return (
        <>
            <PageDescription
                title="Role Groups"
                description="Role Groups are used to assign roles to users."
                buttons={[
                    {
                        label: "Add Role Group",
                        onClick: () => {
                            setIsEdit(false);
                            setShowAddEditGroup(true);
                        },
                        icon: "fa-plus",
                    },
                ]}
            />
            {roleGroupQuery.isLoading ? (
                <Loader2 />
            ) : (
                <TableNeogen
                    entries={roleGroupQuery.data ?? []}
                    ignoreFields={["roleIds"]}
                    formatters={[
                        {
                            field: "isPublic",
                            type: "Boolean",
                        },
                        {
                            field: "owner",
                            type: "Company",
                        },
                    ]}
                    actions={[
                        {
                            label: "Edit",
                            icon: "fal fa-edit",
                            onClick: async (entry) => {
                                setGroup(roleGroupQuery.data?.find((r) => r.id === entry));
                                setIsEdit(true);

                                setRoles(roleGroupQuery.data?.find((r) => r.id === entry)?.roleIds ?? []);
                                setShowAddEditGroup(true);
                            },
                        },
                        {
                            label: "Delete",
                            icon: "fal fa-trash-alt",
                            onClick: async (entry) => {
                                SwalNeogenFire({
                                    title: "Are you sure?",
                                    text: "You won't be able to revert this!",
                                    icon: "warning",
                                    showCancelButton: true,
                                    confirmButtonText: "Yes, delete it!",
                                    confirmButtonColor: "#3085d6",
                                }).then(async (result) => {
                                    if (result.value) {
                                        console.error(entry);
                                        await roleGroupService.deleteByID(entry);
                                        queryCache.invalidateQueries(["roleGroupEntries"]);
                                        SwalNeogenFire({
                                            title: "Deleted!",
                                            text: "Your file has been deleted.",
                                            icon: "success",
                                            showConfirmButton: false,
                                            timer: 1500,
                                        });

                                        // queryCache.invalidateQueries(["roleGroups"]);
                                    }
                                });
                            },
                        },
                    ]}
                />
            )}
            <AddEditRoleGroup
                show={showAddEditGroup}
                close={() => setShowAddEditGroup(false)}
                isEdit={isEdit}
                roles={roles}
                group={group}
            />
        </>
    );
}
