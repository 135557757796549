import { useEffect, useState } from "react";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useNavigate } from "react-router";
import Swal from "sweetalert2";
import businessRuleTypeService from "../../../services/business-rule-type.service";
import businessRuleService from "../../../services/business-rule.service";
import { BusinessRuleGroup } from "../../../typings/api/business-rule-group";
import { BusinessRuleType } from "../../../typings/api/business-rule-type";
import ButtonNeoGen from "../../../layout/button-neogen";
import ModalDialog from "../../../layout/modal-dialog";
import PrintPre from "../../../layout/print-pre";
import TableNeogen from "../../../layout/table-neogen";
import Loader2 from "../../utilities/Loader2";
import AddEditRuleType from "./add-edit-rule-type";
import SwalNeogenFire from "../../../layout/swal-neogen";

export default function ManageRuleTypes(props: ManageRuleTypeProps) {
    const cache = useQueryClient();
    const navigate = useNavigate();
    const [editing, setEditing] = useState<BusinessRuleType>();
    const [showEdit, setShowEdit] = useState(false);
    useEffect(() => {
        setEditing(undefined);
        setShowEdit(false);
    }, [props.show]);

    const ruleTypeQuery = useQuery(["business-rule-types2"], async () => {
        const response = await businessRuleTypeService.getAll();
        if (response) {
            return response.data;
        } else {
            return [];
        }
    });
    async function save() {
        // try {
        //     const response = await businessRuleService.create(name, description);
        //     if (response.id) {
        //         navigate("/business-rules");
        //     } else {
        //         setError(response.message);
        //     }
        // } catch (error: any) {
        //     setError(error.message);
        // }
    }

    return (
        <>
            <ModalDialog
                show={props.show}
                title="Manage Rule Types"
                size="md"
                okAction={save}
                close={props.close}
                showOk={false}
                cancelText="Close"
            >
                {ruleTypeQuery.isLoading ? (
                    <Loader2 />
                ) : (
                    <>
                        <ButtonNeoGen
                            onClick={() => {
                                setEditing(undefined);
                                setShowEdit(true);
                            }}
                        >
                            <i className="fas fa-plus"></i> Add New Rule Type
                        </ButtonNeoGen>

                        <TableNeogen
                            entries={
                                (ruleTypeQuery.data ?? [])?.map((entry) => {
                                    return {
                                        id: entry.id,
                                        name: entry.name,
                                        description: entry.description,
                                    };
                                }) ?? []
                            }
                            actions={[
                                {
                                    label: "Edit",
                                    icon: "fal fa-pencil",
                                    onClick: (id: number) => {
                                        const entry = ruleTypeQuery.data?.find((predicate) => {
                                            console.error(`${predicate.id} == ${id}`);

                                            return Number(predicate.id) === Number(id);
                                        });
                                        setEditing(entry);
                                        // console.error(entry);
                                        // alert(id);
                                        setShowEdit(true);
                                    },
                                },
                                {
                                    label: "Delete",
                                    className: "bg-red-500",
                                    icon: "fal fa-trash-alt",
                                    onClick: (id: number) => {
                                        // businessRuleService.delete(entry.id);
                                        // navigate("/business-rules");
                                        SwalNeogenFire({
                                            title: "Are you sure?",
                                            text: "You won't be able to revert this!",
                                            icon: "warning",
                                            showCancelButton: true,
                                            confirmButtonColor: "#3085d6",
                                            cancelButtonColor: "#d33",
                                        }).then(async (result) => {
                                            if (result.value) {
                                                await businessRuleTypeService.deleteByID(id);
                                                await cache.invalidateQueries(["rule-types"]);
                                                await cache.invalidateQueries(["business-products"]);
                                                await cache.invalidateQueries(["business-rule-types"]);
                                                await cache.invalidateQueries(["business-rule-types2"]);
                                                SwalNeogenFire({
                                                    title: "Deleted!",
                                                    text: "Your rule type has been deleted.",
                                                    icon: "success",
                                                    showConfirmButton: false,
                                                    timer: 1500,
                                                }).then(() => {
                                                    console.log("deleted");
                                                });
                                            }
                                        });
                                    },
                                },
                            ]}
                        />
                    </>
                )}
            </ModalDialog>
            <AddEditRuleType show={showEdit} close={() => setShowEdit(false)} ruleType={editing} />
        </>
    );
}

type ManageRuleTypeProps = {
    show: boolean;
    close: () => void;
    // group?: BusinessRuleGroup;
};
