import { useContext, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import Swal from "sweetalert2";
import clientsService from "../../services/clients.service";
import UserContext from "../../services/user-context";
import PageDescription from "../../layout/page-description";
import PrintPre from "../../layout/print-pre";
import TableNeogen from "../../layout/table-neogen";
import Loader2 from "../utilities/Loader2";
import AddEditClientModal from "./modals/add-edit-client";
import { useRecoilState } from "recoil";
import userAtom from "../../atoms/userAtom";
import { ClearERCUser } from "../../typings/api/clear-erc-user";
import SwalNeogenFire from "../../layout/swal-neogen";

export default function Clients() {
    const [showAddClient, setShowAddClient] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [user, setUser] = useRecoilState<ClearERCUser>(userAtom);

    const clientsQuery = useQuery(["Clients"], async () => {
        const response = await clientsService.getAllWithRelations(user.company ?? -1);
        if (response) {
            // console.error(response);
            return response.data;
        }
    });

    return (
        <>
            {clientsQuery.isLoading ? (
                <Loader2 />
            ) : (
                <>
                    <PageDescription
                        title="Clients"
                        description="Clients who have signed up for your services."
                        buttons={[
                            {
                                label: "Add Client",
                                icon: "fas fa-plus",
                                onClick: () => {
                                    setShowAddClient(true);
                                },
                            },
                        ]}
                    />
                    {/* <PrintPre>
                        {clientsQuery.data}
                    </PrintPre> */}
                    <TableNeogen
                        entries={clientsQuery.data ?? []}
                        ignoreFields={[
                            "active",
                            "salesUserId",
                            "accountManagerId",
                            "companyCreatedId",
                            "payrollContactId",
                            "account_manager_id",
                            "company_created_id",
                            "payroll_contact_id",
                            "salesUser",
                            "accountManager",
                            "companyCreated",
                            "lastUpdated",
                            "email",
                        ]}
                        actions={[
                            {
                                label: "Edit",
                                icon: "fas fa-edit",
                                onClick: (entry) => {
                                    setIsEdit(true);
                                    setShowAddClient(true);
                                },
                            },
                            {
                                label: "Send Invite",
                                icon: "fal fa-envelope",
                                className: "bg-blue-600 text-white break-normal",
                                onClick: (entry) => {
                                    alert("send");
                                },
                            },
                            {
                                label: "Delete",
                                icon: "fal fa-trash",
                                className: "bg-red-600 text-white break-normal",
                                onClick: (entry) => {
                                    SwalNeogenFire({
                                        title: "Are you sure?",
                                        text: "You won't be able to revert this!",
                                        icon: "warning",
                                        showCancelButton: true,
                                        confirmButtonColor: "#3085d6",
                                    }).then((result) => {
                                        console.error(entry);
                                        if (result.value) {
                                            clientsService
                                                .deleteByID(Number(entry))
                                                .then(() => {
                                                    SwalNeogenFire({
                                                        title: "Deleted",
                                                        text: "Client has been deleted",
                                                        icon: "success",
                                                        showConfirmButton: false,
                                                        timer: 2000,
                                                    })
                                                        .then(() => {
                                                            clientsQuery.refetch();
                                                        })
                                                        .catch((e) => {
                                                            SwalNeogenFire({
                                                                title: "Error",
                                                                text: "Error deleting client" + e.message,
                                                                icon: "error",
                                                                showConfirmButton: false,
                                                                timer: 2000,
                                                            });
                                                        })
                                                        .finally(() => {
                                                            clientsQuery.refetch();
                                                        });
                                                })
                                                .catch((e) => {
                                                    console.error(e);
                                                    SwalNeogenFire({
                                                        title: "Error",
                                                        text: "Error deleting client" + e.message,
                                                        icon: "error",
                                                        showConfirmButton: false,
                                                        timer: 2000,
                                                    });
                                                });
                                        }
                                    });
                                },
                            },
                        ]}
                        formatters={[
                            {
                                field: "salesUser",
                                type: "DisplayItem",
                                itemToDisplay: "email",
                            },
                            {
                                field: "accountManager",
                                type: "DisplayItem",
                                itemToDisplay: "name",
                            },
                            {
                                field: "payrollContact",
                                type: "DisplayItem",
                                itemToDisplay: "name",
                            },
                            {
                                field: "companyCreated",
                                type: "DisplayItem",
                                itemToDisplay: "name",
                            },
                            {
                                field: "dateCreated",
                                type: "Date",
                            },
                            {
                                field: "lastUpdated",
                                type: "Date",
                            },
                            {
                                field: "active",
                                type: "Boolean",
                            },
                        ]}
                    />
                    <AddEditClientModal show={showAddClient} close={() => setShowAddClient(false)} isEdit={isEdit} />
                </>
            )}
        </>
    );
}
