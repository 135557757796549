import { FileUploadUtil } from "./../../../utilities/file-upload";
import { useContext, useState } from "react";
import { useQueryClient } from "@tanstack/react-query";
import Swal from "sweetalert2";
import fileUploadService from "../../../../services/file.service";
import UserContext from "../../../../services/user-context";
import ModalDialog from "../../../../layout/modal-dialog";
import { assumedUserAtom } from "../../../../atoms/userAtom";
import { useRecoilState } from "recoil";
import SwalNeogenFire from "../../../../layout/swal-neogen";

export default function AddFile(props: AddFileProps) {
    const [selectedFile, setSelectedFile] = useState<File | null>(null);
    const [user] = useContext(UserContext);
    const [assumedUser, setAssumedUser] = useRecoilState(assumedUserAtom);
    const queryCache = useQueryClient();
    function onFileChange(event: any) {
        // Update the state
        setSelectedFile(event.target.files[0]);
    }
    async function onFileUpload() {
        // Create an object of formData
        if (!selectedFile) {
            SwalNeogenFire({
                title: "Error",
                text: "You need to select a file",
                icon: "error",
            });
            return;
        } else {
            console.error({ assumedUser });
            const res = await fileUploadService.uploadFile(selectedFile, assumedUser?.id ?? user.id ?? "");
            if (res && res.status === 200) {
                props.close();
                queryCache.invalidateQueries(["files"]);
                SwalNeogenFire({
                    title: "Success",
                    text: "File uploaded successfully",
                    icon: "success",
                    showConfirmButton: false,
                    timer: 1500,
                });
                return;
            }
        }
        SwalNeogenFire({
            title: "Error",
            text: "File upload failed",
            icon: "error",
            showConfirmButton: false,
            timer: 1500,
        });

        // Details of the uploaded file

        // Request made to the backend api
        // Send formData object
        // axios.post("api/uploadfile", formData);
    }
    return (
        <ModalDialog
            close={props.close}
            show={props.show}
            title={"Upload File"}
            okAction={onFileUpload}
            okText="Upload File"
        >
            <FileUploadUtil onFileChange={onFileChange} />
            {/* <PrintPre> */}
            <div className="flex mx-auto items-center justify-center bg-gray-200 my-10 p-3 rounded-xl">
                {selectedFile?.name}
            </div>

            {/* </PrintPre> */}
        </ModalDialog>
    );
}

type AddFileProps = {
    show: boolean;
    close: () => void;
};
