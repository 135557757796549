import { useState } from "react";
import { User } from "../../../../typings/api";
import InputControlled from "../../../../layout/input-controlled";
import ModalDialog from "../../../../layout/modal-dialog";
import PrintPre from "../../../../layout/print-pre";
import AdminUserService from "../../../../services/users.service";
import { uuid4 } from "@sentry/utils";
import _ from "lodash";
import { useQueryClient } from "@tanstack/react-query";
import Swal from "sweetalert2";
import { ErcUser } from "../../../../typings/api/erc-user";
import Loader3 from "../../../utilities/Loader3";
import { ClearERCUser } from "../../../../typings/api/clear-erc-user";
import SwalNeogenFire from "../../../../layout/swal-neogen";

export default function ResetPassword(props: ResetPasswordProps) {
    const [password1, setPassword1] = useState("");
    const [password2, setPassword2] = useState("");
    const [resetting, setResetting] = useState(false);
    const queryCache = useQueryClient();
    if (!props.user) {
        return <></>;
    }

    async function saveUser() {
        setResetting(true);
        if (password1 !== password2) {
            SwalNeogenFire({
                title: "Error",
                text: "Passwords do not match",
                icon: "error",
                showConfirmButton: false,
                timer: 1500,
            });
            setResetting(false);
            return;
        }
        if (password1.length < 8) {
            SwalNeogenFire({
                title: "Error",
                text: "Password must be at least 8 characters",
                icon: "error",
                showConfirmButton: false,
                timer: 1500,
            });
            setResetting(false);
            return;
        }
        if (props.user === null) {
            return;
        }
        props.user.stPass = props.user.stPass ?? "";
        props.user.stUrl = props.user.stUrl ?? "";
        props.user.stCompany = props.user.stCompany ?? -1;
        if (props.user.id && props.user.verificationToken && props.user.verificationToken.length > 0) {
            try {
                const pwd = await AdminUserService.resetPasswordComplete(
                    props.user.id,
                    props.user.verificationToken,
                    password1,
                );
                const r = await queryCache.invalidateQueries(["users"]);
                setResetting(false);
                SwalNeogenFire({
                    title: "Success",
                    text: "Password reset successfully",
                    icon: "success",
                    showConfirmButton: false,
                    timer: 1500,
                });
                props.close();
            } catch (e) {
                console.log("ERROR: ", e);
                SwalNeogenFire({
                    title: "Error",
                    text: "Password reset failed",
                    icon: "error",
                    showConfirmButton: false,
                    timer: 1500,
                });
                setResetting(false);
            }
        } else if (props.user.id) {
            // We have a user but they need a verification token
            try {
                const user = _.cloneDeep(props.user);
                user.verificationToken = uuid4();
                props.user.verificationToken = user.verificationToken;
                delete user.lastLoggedIn;
                delete user.password;
                delete user.emailVerified;
                // delete (user.verificationToken);
                delete user.realm;
                const patch = await AdminUserService.patchURL("users/" + user.id, user).catch((e) => {
                    console.log("PATCH: ", e.response);
                    throw e;
                });
                const pwd = await AdminUserService.resetPasswordComplete(
                    props.user.id,
                    user.verificationToken,
                    password1,
                );
                console.log({ patch, pwd });
                const r = await queryCache.invalidateQueries(["users"]);
                setResetting(false);
                SwalNeogenFire({
                    title: "Success",
                    text: "Password reset successfully",
                    icon: "success",
                    showConfirmButton: false,
                    timer: 1500,
                });
                props.close();
            } catch (e) {
                console.log("ERROR: ", e);
                SwalNeogenFire({
                    title: "Error",
                    text: "Password reset failed",
                    icon: "error",
                    showConfirmButton: false,
                    timer: 1500,
                });
                setResetting(false);
            }
        }
    }

    return (
        <>
            <ModalDialog title="Reset Password" close={props.close} show={props.show} okAction={saveUser}>
                {resetting ? (
                    <>
                        <h1>Please wait, resetting password</h1>
                        <Loader3 />
                    </>
                ) : (
                    <>
                        <InputControlled
                            label="New Password"
                            type="password"
                            value={password1}
                            setValue={setPassword1}
                            name="password1"
                            autoComplete="new-password"
                        />
                        <InputControlled
                            label="New Password Again"
                            type="password"
                            value={password2}
                            setValue={setPassword2}
                            name="password2"
                            autoComplete="new-password"
                        />
                    </>
                )}
            </ModalDialog>
        </>
    );
}

type ResetPasswordProps = {
    show: boolean;
    close: () => void;
    user: ClearERCUser | null;
};
